<template>
  <div id="materialOther">
    <el-dialog
      :title="materialOtherFormTitle"
      width="1500px"
      :visible.sync="materialOtherDialogVisible"
      :close-on-click-modal="false"
      @close="materialOtherDialogClose"
    >
      <el-form
        ref="materialOtherFormRef"
        :model="materialOtherForm"
        :rules="materialOtherFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-input v-model="materialOtherForm.name" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号规格" prop="spec">
              <el-input v-model="materialOtherForm.spec" placeholder="请输入型号规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="materialOtherForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="materialOtherForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="厂家" prop="manufactor">
              <el-input v-model="materialOtherForm.manufactor" placeholder="请输入厂家" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="到货日期" prop="arrivalDate">
              <el-date-picker v-model="materialOtherForm.arrivalDate" placeholder="请选择到货日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="有效期" prop="validDate">
              <el-date-picker v-model="materialOtherForm.validDate" placeholder="请选择有效期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所在位置" prop="location">
              <el-input v-model="materialOtherForm.location" placeholder="请输入所在位置" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="出入库情况">
          <vxe-toolbar
            v-if="materialOtherFormTitle === '新增其他'
              || materialOtherFormTitle === '修改其他'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column field="outInSituation" title="出入库情况" width="150">
              <template v-slot="{row,column}">
                <vxe-radio-group v-model="row[column.property]">
                  <vxe-radio :label="1" content="出库" />
                  <vxe-radio :label="2" content="入库" />
                </vxe-radio-group>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="applyDept"
              title="申请部门"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="operator"
              title="操作人"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="situationExplain"
              title="情况说明"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="outInQuantity"
              title="出入库数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="balanceQuantity"
              title="结余数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="outInFormNo"
              title="出入库申请单编号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="materialOtherFormTitle === '新增其他'
                || materialOtherFormTitle === '修改其他'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="materialOtherDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="materialOtherFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input v-model="searchForm.name" placeholder="请输入名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['CC_MATERIAL_OTHER_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="materialOtherPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="manufactor" label="厂家" />
      <el-table-column label="到货日期">
        <template slot-scope="scope">
          <span v-if="scope.row.arrivalDate">{{ scope.row.arrivalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="有效期">
        <template slot-scope="scope">
          <span v-if="scope.row.validDate">{{ scope.row.validDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="location" label="所在位置" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['CC_MATERIAL_OTHER_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['CC_MATERIAL_OTHER_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="materialOtherPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addMaterialOther,
  deleteMaterialOther,
  updateMaterialOther,
  selectMaterialOtherInfo,
  selectMaterialOtherList
} from '@/api/storage/materialOther'

export default {
  data () {
    return {
      materialOtherDialogVisible: false,
      materialOtherFormTitle: '',
      materialOtherForm: {
        id: '',
        name: '',
        spec: '',
        batchNo: '',
        quantity: '',
        manufactor: '',
        arrivalDate: '',
        validDate: '',
        location: '',
        detailJson: ''
      },
      materialOtherFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      materialOtherPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      detailList: []
    }
  },
  created () {
    selectMaterialOtherList(this.searchForm).then(res => {
      this.materialOtherPage = res
    })
  },
  methods: {
    materialOtherDialogClose () {
      this.$refs.materialOtherFormRef.resetFields()
      this.detailList = []
    },
    materialOtherFormSubmit () {
      if (this.materialOtherFormTitle === '其他详情') {
        this.materialOtherDialogVisible = false
        return
      }
      this.$refs.materialOtherFormRef.validate(async valid => {
        if (valid) {
          this.materialOtherForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.materialOtherFormTitle === '新增其他') {
            await addMaterialOther(this.materialOtherForm)
          } else if (this.materialOtherFormTitle === '修改其他') {
            await updateMaterialOther(this.materialOtherForm)
          }
          this.materialOtherPage = await selectMaterialOtherList(this.searchForm)
          this.materialOtherDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.materialOtherFormTitle = '新增其他'
      this.materialOtherDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaterialOther(row.id)
        if (this.materialOtherPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.materialOtherPage = await selectMaterialOtherList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.materialOtherFormTitle = '修改其他'
      this.materialOtherDialogVisible = true
      this.selectMaterialOtherInfoById(row.id)
    },
    handleInfo (index, row) {
      this.materialOtherFormTitle = '其他详情'
      this.materialOtherDialogVisible = true
      this.selectMaterialOtherInfoById(row.id)
    },
    selectMaterialOtherInfoById (id) {
      selectMaterialOtherInfo(id).then(res => {
        this.materialOtherForm.id = res.id
        this.materialOtherForm.name = res.name
        this.materialOtherForm.spec = res.spec
        this.materialOtherForm.batchNo = res.batchNo
        this.materialOtherForm.quantity = res.quantity
        this.materialOtherForm.manufactor = res.manufactor
        this.materialOtherForm.arrivalDate = res.arrivalDate
        this.materialOtherForm.validDate = res.validDate
        this.materialOtherForm.location = res.location
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaterialOtherList(this.searchForm).then(res => {
        this.materialOtherPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaterialOtherList(this.searchForm).then(res => {
        this.materialOtherPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaterialOtherList(this.searchForm).then(res => {
        this.materialOtherPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
