import axios from '@/common/axios'
import qs from 'qs'

export function addMaterialOther (data) {
  return axios({
    method: 'post',
    url: '/storage/materialOther/add',
    data: qs.stringify(data)
  })
}

export function deleteMaterialOther (id) {
  return axios({
    method: 'delete',
    url: '/storage/materialOther/delete/' + id
  })
}

export function updateMaterialOther (data) {
  return axios({
    method: 'put',
    url: '/storage/materialOther/update',
    data: qs.stringify(data)
  })
}

export function selectMaterialOtherInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/materialOther/info/' + id
  })
}

export function selectMaterialOtherList (query) {
  return axios({
    method: 'get',
    url: '/storage/materialOther/list',
    params: query
  })
}
